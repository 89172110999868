<template>
    <nav id="skipNavi">
        <a href="#contents"></a>
    </nav>
    <div id="wrapper">
        <router-view/>
    </div>
</template>

<script>
import {reactive} from 'vue';
import {useHead} from "@vueuse/head";

import "@/assets/css/admin/common.css"
import "@/assets/css/admin/page.css"


import '@/assets/js/dialog'
import '@/assets/js/admin/global'
import '@/assets/js/admin/common'


export default {
    name: "login",
    setup(){
        const siteData = reactive({
            title: `KT 차세대매장 디지털 컨텐츠 운영 시스템`
        });
        useHead({
            htmlAttrs:{
                lang: 'kr',
            },
            meta:[
                {
                    name: 'apple-mobile-web-app-title',
                    content: siteData.title,
                },
                {
                    name: 'author',
                    content: siteData.title,
                },
                {
                    name: 'description',
                    content: siteData.title,
                },
                {
                    name: 'theme-color',
                    content: 'black',
                },
                {
                    name: 'og:type',
                    property: 'og:type',
                    content: 'website',
                },
                {
                    name: 'og:title',
                    property: 'og:title',
                    content: siteData.title,
                },
                {
                    name: 'og:site_name',
                    property: 'og:site_name',
                    content: siteData.title,
                },
                {
                    name: 'og:description',
                    property: 'og:description',
                    content: siteData.title,
                },
                {
                    name: 'og:image',
                    property: 'og:image',
                    content: require('@/assets/img/common/og_img.png'),
                },
                {
                    property: 'og:image:width',
                    content: '1200',
                },
                {
                    property: 'og:image:height',
                    content: '600',
                }
            ],
            link:[
                {
                    rel:'shortcut icon',
                    href: require('@/assets/img/common/favicon.webp'),
                    type: 'image/x-icon'
                },
                {
                    rel:'apple-touch-icon-precomposed',
                    sizes: '180x180',
                    href: require('@/assets/img/common/mobile_icon_180x180.webp'),
                },
                {
                    rel:'apple-touch-icon-precomposed',
                    href: require('@/assets/img/common/mobile_icon.webp'),
                },
                {
                    rel:'stylesheet',
                    href: '//code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css',
                }
            ],
        })
    }
};
</script>

<style scoped>
</style>